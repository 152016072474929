import React, { useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, Transition } from "react-transition-group";
import { gsap } from "gsap";

import AnimConf from "./conf/animation";

import Menu from "./components/Menu";
import HomePage from "./pages/Home";
import ArtPage from "./pages/Art";
import ProjectsPage from "./pages/Projects";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";

import "./App.css";
import 'animate.css';

const App = (props) => {
	const location = useLocation();
	const pageRef = useRef(null);

	const onEnter = (node) => {
		gsap.set(node, { y: 0, opacity: 0 });
		gsap.to(node, { duration: AnimConf.pageTransitionDuration, y: 0, opacity: 1, ease: "Power2.out" });
	}

	const onExit = (node) => {
		gsap.to(node, { duration: AnimConf.pageTransitionDuration, y: 0, ease: "Power2.in" });
	}

	return (	
	<div className="App">
		<Menu location={location} />
		<main ref={pageRef}>
		<TransitionGroup component={null}>
			<Transition
				appear
				key={location.key}
				timeout={AnimConf.pageTransitionDuration}
				onEnter={onEnter}
				onExit={onExit}
			>
			<Routes location={location}>
				<Route path="/" >
					<Route index element={<HomePage />} />
					<Route path="art" element={<ArtPage />} />
					<Route path="projects" element={<ProjectsPage />} >
						<Route path=":id" element={<ProjectsPage />} />
						<Route path="type/:type" element={<ProjectsPage />} />
					</Route>
					<Route path="about" element={<AboutPage />} />
					<Route path="contact" element={<ContactPage />} />
					<Route path="*" element={<HomePage />} />
				</Route>
			</Routes>
			</Transition>
		</TransitionGroup>
		</main>
	</div>
	);
}

export default App;
