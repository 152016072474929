import React, { useState, useRef } from "react";
import { gsap, Power2 } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin.js";
import ProjectCover from "./ProjectCover";
import ProjectDetails from "./ProjectDetails";

import styles from "./Project.module.css";

const plugins = [ScrollToPlugin];

gsap.registerPlugin(ScrollToPlugin);

function Project(props) {
  const { project, in: show, index = 0, autoScroll } = props;
  const [isActive, setIsActive] = useState(false || !!props.isActive);
  const containerRef = useRef(null);
  const detailsRef = useRef(null);

  const handleOpen = () => {
    if (!isActive) {
      setIsActive(true);
    } else {
      gsap.to(window, {
        duration: 1,
        scrollTo: detailsRef.current,
        ease: "Power2.out"
      });
    }
  };

  const handleClose = () => {
    setIsActive(false);
  };

  return (
    <div id={`p${project.id}`} className={styles.container} ref={containerRef} style={{ opacity: 0 }}>
      <ProjectCover project={project} onActivate={handleOpen} />
      {isActive && (
        <>
          <div ref={detailsRef} />
          <ProjectDetails project={project} autoScroll={autoScroll} onClose={handleClose} />
        </>
      )}
    </div>
  );
}

export default Project;
