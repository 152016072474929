import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./About.module.css";

import img_profile from "../assets/images/portrait_pro_small.jpg";

function About(props) {
	return (
		<div className={styles.content}>
			<div className={styles.presentation}>
				<img
					className={styles.portrait}
					src={img_profile}
					alt="About me"
				/>
				<p>
					Lunographe est mon pseudonyme créatif et mon vrai nom, Emmanuel Kodjo.{" "}
					J’ai au cours des vingt dernières années, créé des œuvres interactives, des court-métrages et des contenus multimédia publiés sur les réseaux sociaux.
				</p>
				<p>
					En 2002, mon clip interactif Hé(M)atome, co-réalisé, reçoit une mention spéciale au concours de Net-art du festival Villette numérique à Paris, avant d’être présenté à différentes manifestations au niveau national et international.{" "} 
					Par la suite, la mairie de Viry-Châtillon m’invite à son exposition Mon Oeil!, pour laquelle je crée l'installation interactive Pixel in love (2006). Elle sera exposée au festival FILE de São Paulo.{" "}
					Je me consacre ensuite au court-métrage avec, notamment, le film Marcel (2013). Aujourd’hui, je crée des contenus sur les réseaux sociaux et développe le projet Nantes imaginaire.{" "}
				</p>
				<p>
					Mes créations sont souvent surréalistes, poétiques, allégoriques ou contemplatives et évoquent des thèmes divers (solitude, inconscient, technologie, anthropocentrisme, nature, identité, altérité...).
				</p>
			</div>
		</div>
	);
}

export default About;
