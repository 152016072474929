import React, { useState } from "react";
import { Transition } from "react-transition-group";
import { gsap } from "gsap";

import ProjectsNav from "../components/ProjectsNav";

import AnimConf from "../conf/animation";

import styles from "./Art.module.css";

function Art(props) {

	const [navLoaded, setNavLoaded] = useState(false);

	return (
		<div className={styles.content}>
			<div className={styles["nav-section"]}>
				<ProjectsNav isPro={false} triggerLoaded={() => setNavLoaded(true)} />
			</div>
		</div>
	);
}

export default Art;