import React from "react";

import ContactBar from "../components/ContactBar";
import styles from "./Contact.module.css";

function Contact(props) {
	return (		
		<div className={styles.content}>
			<div className={styles.details}>
        <p>
          Email :<br/>
          <a href="mailto:contact@lunographe.com">contact@lunographe.com</a>
        </p>
			</div>
			<ContactBar socialOnly />
		</div>
	);
}

export default Contact;
