import React from "react";
import styles from "./ProjectCover.module.css";

function ProjectCover(props) {
  const {
    project,
    onActivate
  } = props;

  const handleClick = e => {
    onActivate();
    e.preventDefault();
  };

  const getCoverUrl = id => {
    return `portfolio/assets/images/covers/${id}.jpg`;
  };

  const getRolesEl = () => {
    return (
      <ul className={styles.roles}>
      {
        project.roles.map((role, i) => (
          <li key={i}>{role.label}</li>
        ))
      }
      </ul>
    );
  };

  return (
    <div
      className={styles.container}
      onClick={handleClick}
      style={{ backgroundImage: `url(${getCoverUrl(project.id)})` }}
    >
      <div className={styles.desc}>
        <p className={styles.title}>{project.title}</p>
        <p className={styles["tech-data"]}>
          {project.type.label}
          {project.duration && ` / ${project.duration}`}
          {project.genre.label && ` / ${project.genre.label}`}
        </p>
        <p className={styles.synopsis}>{project.synopsis}</p>
        {getRolesEl()}  
      </div>
    </div>
  );
}

export default ProjectCover;
