import React from "react";
import { Link } from "react-router-dom";

import styles from "./ContactBar.module.css";

import { ReactComponent as MailIcon } from "../assets/svg/mail.svg";
import { ReactComponent as FacebookIcon } from "../assets/svg/facebook.svg";
import { ReactComponent as InstagramIcon } from "../assets/svg/instagram.svg";

function ContactBar(props) {  
  return (
    <div className={styles.container}>
      <ul className={styles.icons}>
      {
        !props.socialOnly &&
        <li>
          <Link to="/contact">
            <MailIcon className={styles.icon} />
          </Link>
        </li>
      }
        <li>
          <a href="https://www.instagram.com/lunographe">
            <InstagramIcon className={styles.icon} />
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/lunographe">
            <FacebookIcon className={styles.icon} />
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ContactBar;
